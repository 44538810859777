import React from 'react';
import Link from 'next/link';

interface IFooterLink {
    href: string;
    label: string;
    target?: string;
}

export const FooterLink: React.FC<IFooterLink> = (props) => {
    const { href, label, target } = props;

    return (
        <Link href={href} className="text-md font-semibold text-neutral-100" target={target}>
            <span>{label}</span>
        </Link>
    );
};
