import React from 'react';
import { FooterLink } from './elements/FooterLink';
import { LogoCloudbar } from '../logoCloudbar/LogoCloudbar';

interface IFooter {}

export const Footer: React.FC<IFooter> = () => {
    return (
        <footer className="absolute bottom-0 flex min-h-48 w-full gap-2">
            <div className="mx-auto flex w-full max-w-4xl flex-col gap-6 px-8 py-8 md:flex-row md:gap-0">
                <div className="flex grow flex-col items-center gap-2 md:flex-row md:gap-2">
                    <LogoCloudbar />
                    <span className="pl-4 text-xs text-neutral-300 md:text-sm">
                        powered by webbar GmbH
                    </span>
                </div>
                <div className="flex shrink flex-row flex-nowrap items-center justify-center gap-8 md:justify-end">
                    <FooterLink label="Home" href="/" />
                    <FooterLink label="Pricing" href="/pricing" />
                    {/*<FooterLink*/}
                    {/*    label="Roadmap"*/}
                    {/*    href="https://roadmap.cloudbar.site"*/}
                    {/*    target="roadmap"*/}
                    {/*/>*/}
                    <FooterLink label="Legal" href="/legal" />
                    <FooterLink label="Privacy" href="/privacy" />
                </div>
            </div>
        </footer>
    );
};
