import React from 'react';
import Link from 'next/link';

export interface INavigationItem {
    label: string;
    href: string;
    target?: string;
}

export const NavigationItem: React.FC<INavigationItem> = (props) => {
    const { label, href = '#', target } = props;

    return (
        <Link
            href={href}
            className="group text-xl text-neutral-200 hover:text-neutral-50"
            target={target}
        >
            {label}
            <div className="gradient-cb3-cymk block h-[2px] w-full opacity-0 transition-all duration-300 group-hover:opacity-100" />
        </Link>
    );
};
